import React, { useContext } from 'react'
import { OptionsContext } from '/src/contexts/OptionsContext'
import { Link } from 'gatsby'
import loadable from '@loadable/component'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const LayoutInner = loadable(() => import('/src/components/Layout/LayoutInner'))
const PageHeader = loadable(() => import('/src/components/Structure/PageHeader'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const Seo = loadable(() => import('/src/components/Structure/Seo'))

const NotFoundPage = () => {
    const [options] = useContext(OptionsContext)

    const pageTitle = '404: Page not found'

    const post = {
        seo: {
            title: `${pageTitle} - ${options.siteData.title}`,
            metaRobotsNoindex: 'noindex',
            metaRobotsNofollow: 'nofollow'
        }
    }

    const breadcrumb = {
        type: 'back',
        backTitle: 'Homepage',
        uri: '/'
    }

    const journeyLink = {
        url: options.journey.mainJourney.link,
        title: 'Get Free Quotes'
    }

    return (
        <LayoutInner>
            <Seo post={post} />

            <main>
                <PageHeader
                    breadcrumb={breadcrumb}
                    pageTitle={pageTitle}
                    description={"<p>Sorry, we couldn't find that page</p>"}
                />

                <Section>
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <p className="c-lead">
                                The page you are looking for may have been moved or deleted. You can{' '}
                                <Link to={breadcrumb.uri}>return to the homepage</Link> or if you have time, you can get
                                some free life insurance quotes:
                            </p>
                            <ButtonLink data={journeyLink} size={'lg'} icon={'arrow'} />
                        </div>
                    </div>
                </Section>
            </main>
        </LayoutInner>
    )
}

export default NotFoundPage
